<template>
  <GeneralCard title="Adherence Type Trend Chart" :loading="loading">
    <ChartJs
      :initialOptions="initialOptions"
      :data="adherenceData"
      class="chart-height"
    />
  </GeneralCard>
</template>

<script>
import { BehaviorSubject, defer } from "rxjs";
import { finalize, map, scan, delay } from "rxjs/operators";

import GeneralCard from "@/components/GeneralCard.vue";
import ChartJs from "@/components/ChartJs.vue";

import { bar, getColorUsingIndex } from "@/providers/chartJsOptions";
import { server } from "@/providers/http";

export default {
  components: {
    GeneralCard,
    ChartJs,
  },
  subscriptions() {
    const loading = new BehaviorSubject(0).pipe(
      scan((a, b) => a + b),
      map(Boolean)
    );

    const getAdherenceSummary = defer(() => {
      loading.next(1);
      return this.onList().pipe(
        delay(1500),
        finalize(() => {
          loading.next(-1);
        })
      );
    });

    const adherenceData = getAdherenceSummary.pipe(
      map(({ valueOfEachDate, dateLists }) => ({
        labels: dateLists,
        datasets: valueOfEachDate.map((x, index) => ({
          label: x.label,
          data: x.data,
          backgroundColor: getColorUsingIndex(index),
        })),
      }))
    );

    return {
      loading,
      adherenceData,
    };
  },
  data() {
    return {
      initialOptions: bar({
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Adherence Type Trend Chart",
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
      }),
      onList: () => server.get("adherence/trend"),
    };
  },
};
</script>

<style lang="scss" scoped>
.chart-height {
  height: 600px;
}
</style>
