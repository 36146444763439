import { fromFetch } from "rxjs/fetch";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";

function addJsonHeader() {
  return { "Content-Type": "application/json" };
}

function objectToSearchParams(x) {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(x)) {
    searchParams.append(key, value);
  }
  return searchParams.toString();
}

function defineFromFetch(method) {
  return (url, body = {}, otherHeaders = {}) =>
    of(process.env.VUE_APP_BACKEND_URL).pipe(
      switchMap((origin) => {
        const requestOptions = { method: method, headers: {} };
        let requestURL = `${origin}/${url}`;

        if ((method === "GET" || method === "DELETE") && body !== undefined) {
          const searchParams = objectToSearchParams(body);
          requestURL += `?${searchParams}`;
        }

        if (method === "POST" || method === "PATCH" || method === "PUT") {
          requestOptions.body = JSON.stringify(body);
          requestOptions.headers = Object.assign(
            requestOptions.headers,
            addJsonHeader()
          );
        }

        requestOptions.headers = Object.assign(
          requestOptions.headers,
          otherHeaders
        );

        return fromFetch(requestURL, requestOptions).pipe(
          switchMap((r) =>
            r.json().then((x) => {
              if (r.status >= 400) {
                throw x;
              }
              return x;
            })
          )
        );
      })
    );
}

export const server = new Proxy(
  {},
  {
    get: (_, prop) => {
      return defineFromFetch(`${prop.toUpperCase()}`);
    },
  }
);
