<template>
  <v-app>
    <v-container>
      <AdherencePieChart />
    </v-container>
    <v-container>
      <AdherenceTrendChart />
    </v-container>
  </v-app>
</template>

<script>
import AdherencePieChart from "@/components/AdherencePieChart.vue";
import AdherenceTrendChart from "@/components/AdherenceTrendChart.vue";

export default {
  name: "App",
  components: {
    AdherencePieChart,
    AdherenceTrendChart,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
