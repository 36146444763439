<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import { combineLatest, defer, iif, of, throwError, timer } from "rxjs";
import {
  catchError,
  mergeMap,
  pluck,
  shareReplay,
  switchMap,
} from "rxjs/operators";

const err = new Error("DOM no size");

export default {
  props: {
    initialOptions: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
    },
  },
  mounted() {
    const chart$ = initialChart(this.$refs.chart, this.initialOptions);
    const options$ = this.$watchAsObservable("options").pipe(pluck("newValue"));
    const data$ = this.$watchAsObservable("data").pipe(pluck("newValue"));
    const loading$ = this.$watchAsObservable("loading").pipe(pluck("newValue"));
    const optionsChange$ = combineLatest([chart$, options$]);
    const dataChange$ = combineLatest([chart$, data$]);
    const loadingChange$ = combineLatest([chart$, loading$]);

    this.$subscribeTo(optionsChange$, ([chart, x]) => {
      chart.options = x;
      chart.update();
    });

    this.$subscribeTo(dataChange$, ([chart, x]) => {
      if (x.datasets.length === 0) {
        chart.ctx.fillText(
          "No data to display",
          chart.width / 2,
          chart.height / 2
        );
        chart.ctx.restore();
      } else {
        chart.data = x;
        chart.update();
      }
    });

    this.$subscribeTo(loadingChange$, ([chart, x]) => {
      if (x) {
        chart.data = {};
        chart.update();
      }
    });
  },
};

function initialChart(dom, initOptions) {
  return of(dom).pipe(
    switchMap((x) =>
      iif(
        () => !x.offsetHeight || !x.offsetWidth,
        throwError(() => err),
        defer(() => of(new Chart(x, initOptions)))
      )
    ),
    catchError((e, s) =>
      iif(
        () => e === err,
        timer(100).pipe(mergeMap(() => s)),
        throwError(() => e)
      )
    ),
    shareReplay(1)
  );
}
</script>
