<template>
  <GeneralCard title="Adherence Type Pie Chart" :loading="loading">
    <ChartJs
      :initialOptions="initialOptions"
      :data="adherenceData"
      class="chart-height"
    />
  </GeneralCard>
</template>

<script>
import { BehaviorSubject, defer } from "rxjs";
import { finalize, map, scan, pluck } from "rxjs/operators";

import GeneralCard from "@/components/GeneralCard.vue";
import ChartJs from "@/components/ChartJs.vue";

import { pie, color } from "@/providers/chartJsOptions";
import { server } from "@/providers/http";

export default {
  components: {
    GeneralCard,
    ChartJs,
  },
  subscriptions() {
    const loading = new BehaviorSubject(0).pipe(
      scan((a, b) => a + b),
      map(Boolean)
    );

    const getAdherenceSummary = defer(() => {
      loading.next(1);
      return this.onList().pipe(
        finalize(() => {
          loading.next(-1);
        })
      );
    });

    const adherenceData = getAdherenceSummary.pipe(
      pluck("data"),
      map((xs) => ({
        labels: this.labels,
        datasets:
          xs.length === 0
            ? []
            : [
                {
                  data: xs,
                  backgroundColor: color(xs.length),
                },
              ],
      }))
    );

    return {
      loading,
      adherenceData,
    };
  },
  data() {
    return {
      initialOptions: pie({
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
            },
          },
        },
      }),
      onList: () => server.get("adherence"),
      labels: [
        ">12 Hours early",
        "2~12 Hours early",
        "<= 2 Hours early",
        "<2 Hours late",
        "2~12 Hours late",
        ">=12 Hours late",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.chart-height {
  height: 300px;
}
</style>
