import { mergeAll } from "ramda";

const general = () => ({
  data: {},
  options: {},
  plugins: [],
});

export function bar(...args) {
  return mergeAll([
    general(),
    {
      type: "bar",
    },
    ...args
  ]);
}

export function line(...args) {
  return mergeAll([
    general(),
    {
      type: "line",
    },
    ...args
  ]);
}

export function pie(...args) {
  return mergeAll([
    general(),
    {
      type: "pie",
    },
    ...args
  ]);
}

export const defaultColorPool = [
  "#0066CC",
  "#00E5EF",
  "#28d025",
  "#B1B3B3",
  "#ED8B00",
  "#ff3a6a",
  "#A05EB5",
  "#FFFFFF",
  "#FFC72C",
  "#00216D",
  "#32B542",
  "#FD2104",
  "#7ED0E0",
];

export function color(amount) {
  const defaultColorPoolLength = defaultColorPool.length;
  const repeat = (arr, n) => Array(n).fill(arr).flat();

  if (amount <= defaultColorPoolLength)
    return defaultColorPool.slice(0, amount);
  return repeat(
    defaultColorPool,
    Math.floor(amount / defaultColorPoolLength)
  ).concat(defaultColorPool.slice(0, amount % defaultColorPoolLength));
}

export const getColorUsingIndex = (index) => defaultColorPool[index];
