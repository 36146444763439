<template>
  <v-card :loading="loading" class="d-flex flex-column" :elevation="5">
    <div class="card-title">
      <v-tooltip top :disabled="!tooltip">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{ title }}</span>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </div>
    <div><slot /></div>
  </v-card>
</template>

<script>
export default {
  props: ["title", "loading", "tooltip"],
};
</script>

<style scoped>
.card-title {
  text-align: left;
  padding: 8px;
}
</style>
